import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/tmp/vultr-2-runner/work/kastle/kastle/packages/mdx/src/components/Layout/Default.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Form = makeShortcode("Form");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "mariage-clef-en-main-à-nancy"
    }}><strong parentName="h1">{`Mariage Clef`}</strong>{` en main à Nancy`}</h1>
    <h2 {...{
      "id": "un-avantage"
    }}>{`Un avantage`}</h2>
    <p>{`Cette proposition a pour but de vous :`}</p>
    <ul>
      <li parentName="ul">{`Garantir une prestation de qualité exceptionelle`}</li>
      <li parentName="ul">{`Permettre d’obtenir une estimation budgétaire de votre `}<strong parentName="li">{`réception`}</strong></li>
      <li parentName="ul">{`Simplifier la préparation de votre `}<strong parentName="li">{`mariage`}</strong>{` et vous faire gagner du temps`}</li>
      <li parentName="ul">{`Faire profiter de notre expertise de `}<strong parentName="li">{`plus 400 mariages`}</strong>{` !`}</li>
      <li parentName="ul">{`Permettre de concevoir ensemble votre mariage selon vos envies. Vous restez les maîtres de vos choix concernant, la musique, la gastronomie, la décoration des tables …`}</li>
    </ul>
    <h2 {...{
      "id": "le-château-de-morey-un-cadre-idyllique-pour-un-mariage-clef-en-main-exceptionnel"
    }}>{`Le Château de Morey, un cadre idyllique pour un `}<strong parentName="h2">{`mariage clef en main`}</strong>{` exceptionnel`}</h2>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "637px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/351cd925408f2be15ea2ff5b42d8d983/29923/12088123_953329444738792_1187913062825926809_n.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "150.920245398773%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAeABQDASIAAhEBAxEB/8QAGgAAAQUBAAAAAAAAAAAAAAAAAAECAwQFBv/EABYBAQEBAAAAAAAAAAAAAAAAAAEAAv/aAAwDAQACEAMQAAABr69CHJ0RhhUmvXcCg//EABsQAAIDAQEBAAAAAAAAAAAAAAECAAMSEQQQ/9oACAEBAAEFAqxPPVleRnxWrMASwO+QPDqFFMCgfP/EABcRAAMBAAAAAAAAAAAAAAAAAAAQETH/2gAIAQMBAT8B0j//xAAYEQEAAwEAAAAAAAAAAAAAAAAAARESIf/aAAgBAgEBPwGONqVD/8QAHRAAAgIBBQAAAAAAAAAAAAAAAAEQETEhIjJhcf/aAAgBAQAGPwL07hNLJtdHJxUYNI//xAAbEAACAwEBAQAAAAAAAAAAAAABIQARMRBRcf/aAAgBAQABPyElvIZEURYu65oG2zGpA+RWc9hEHt1keAbryGZRA+GNDANK5//aAAwDAQACAAMAAAAQlDJy/8QAGBEAAgMAAAAAAAAAAAAAAAAAAAEQETH/2gAIAQMBAT8QawVn/8QAFxEBAQEBAAAAAAAAAAAAAAAAAQARYf/aAAgBAgEBPxBK1kkAMC5X/8QAHBABAQACAwEBAAAAAAAAAAAAAREAITFBUWGB/9oACAEBAAE/EKAmfM4XBWhoS20eXAzjORiCooeTq+/MOXXdypkg4BsnabxiNVGaL9fzBDuohB1mj4E6HGUSvLkkYcZc/9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/351cd925408f2be15ea2ff5b42d8d983/f994e/12088123_953329444738792_1187913062825926809_n.webp 163w", "/static/351cd925408f2be15ea2ff5b42d8d983/bcd70/12088123_953329444738792_1187913062825926809_n.webp 325w", "/static/351cd925408f2be15ea2ff5b42d8d983/83bc3/12088123_953329444738792_1187913062825926809_n.webp 637w"],
              "sizes": "(max-width: 637px) 100vw, 637px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/351cd925408f2be15ea2ff5b42d8d983/367e5/12088123_953329444738792_1187913062825926809_n.jpg 163w", "/static/351cd925408f2be15ea2ff5b42d8d983/ab07c/12088123_953329444738792_1187913062825926809_n.jpg 325w", "/static/351cd925408f2be15ea2ff5b42d8d983/29923/12088123_953329444738792_1187913062825926809_n.jpg 637w"],
              "sizes": "(max-width: 637px) 100vw, 637px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/351cd925408f2be15ea2ff5b42d8d983/29923/12088123_953329444738792_1187913062825926809_n.jpg",
              "alt": "mariage clef en main",
              "title": "mariage clef en main",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span><strong parentName="p">{`Mariage clef en main`}</strong>{`
Le choix d’une `}<strong parentName="p">{`salle de réception`}</strong>{` est une étape toute aussi cruciale dans la préparation d’un `}<strong parentName="p">{`mariage`}</strong>{`. Le lieu choisi doit en effet plaire aussi bien aux mariés qu’aux convives. Alors on remue ciel et terre pour trouver l’endroit idéal.`}</p>
    <p>{`Tant par son cadre authentique que par sa capacité d’accueil, le Château de Morey fait partie des `}<strong parentName="p">{`lieux de mariage`}</strong>{` rêvés de tous les mariés. Pouvant accueillir près de 70 invités, le château se dresse dans un cadre verdoyant qui fait toute son unicité.`}</p>
    <p>{`Alors si vous cherchez un endroit pour un `}<strong parentName="p">{`mariage clef en main près de Nancy`}</strong>{`, le Château de Morey vous donnera accès à tout ce qu’il vous faut.`}</p>
    <h2 {...{
      "id": "un-mélange-entre-touches-historiques-et-modernité-pour-votre-mariage-clef-en-main"
    }}>{`Un mélange entre touches historiques et modernité pour votre `}<strong parentName="h2">{`Mariage clef en main`}</strong></h2>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "640px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/2b4e4e518b5a879bf4410b0cf46bc522/d6032/chateau_de_morey_mariage.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.25766871165644%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAIDAQT/xAAVAQEBAAAAAAAAAAAAAAAAAAAAAf/aAAwDAQACEAMQAAAB7FxSpAk//8QAGRAAAwADAAAAAAAAAAAAAAAAAAERAhAh/9oACAEBAAEFAlRZc1CH/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFREBAQAAAAAAAAAAAAAAAAAAACH/2gAIAQIBAT8BV//EABkQAAIDAQAAAAAAAAAAAAAAAAABESAxUf/aAAgBAQAGPwJyPtNZ/8QAGxAAAgIDAQAAAAAAAAAAAAAAAAERIUFRcWH/2gAIAQEAAT8hdNOBnRRbI9GJaOH/2gAMAwEAAgADAAAAEITv/8QAFxEBAAMAAAAAAAAAAAAAAAAAACFBUf/aAAgBAwEBPxCk4//EABgRAAIDAAAAAAAAAAAAAAAAAAARASFB/9oACAECAQE/EFY8Qf/EABwQAQADAAIDAAAAAAAAAAAAAAEAESExYUFxgf/aAAgBAQABPxBBo18EKIilVzMiiLzVPuNwLUeGU7ZnUU46ion/2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/2b4e4e518b5a879bf4410b0cf46bc522/f994e/chateau_de_morey_mariage.webp 163w", "/static/2b4e4e518b5a879bf4410b0cf46bc522/bcd70/chateau_de_morey_mariage.webp 325w", "/static/2b4e4e518b5a879bf4410b0cf46bc522/797b9/chateau_de_morey_mariage.webp 640w"],
              "sizes": "(max-width: 640px) 100vw, 640px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/2b4e4e518b5a879bf4410b0cf46bc522/367e5/chateau_de_morey_mariage.jpg 163w", "/static/2b4e4e518b5a879bf4410b0cf46bc522/ab07c/chateau_de_morey_mariage.jpg 325w", "/static/2b4e4e518b5a879bf4410b0cf46bc522/d6032/chateau_de_morey_mariage.jpg 640w"],
              "sizes": "(max-width: 640px) 100vw, 640px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/2b4e4e518b5a879bf4410b0cf46bc522/d6032/chateau_de_morey_mariage.jpg",
              "alt": "mariage nancy",
              "title": "mariage nancy",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span><strong parentName="p">{`Mariage Nancy`}</strong></p>
    <p>{`Pour combler vos rêves de petite fille de se `}<strong parentName="p">{`marier`}</strong>{` dans un `}<strong parentName="p">{`château`}</strong>{`, le Château de Morey vous ouvre ses portes. L’endroit se trouve en pleine campagne, ce qui donne déjà une idée du cadre qui l’entoure.`}</p>
    <p>{`A l’extérieur se trouvent des arbres centenaires qui marquent son aspect de château du XVIe. Ces arbres sont plantés dans un grand jardin avec une allée cavalière.`}</p>
    <p>{`En franchissant la porte du château, vous et vos convives découvrirez un cadre spacieux fait en pierre apparente. Une autre salle ornée d’une fresque fera office de salle de danse. Et non loin se trouve également un salon équipé de tables et de chaises spécialement conçu pour les enfants.`}</p>
    <h2 {...{
      "id": "un-endroit-qui-saccorde-à-différents-thèmes-de-mariage"
    }}>{`Un endroit qui s’accorde à différents thèmes de `}<strong parentName="h2">{`mariage`}</strong></h2>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "640px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/26a0712157345476aee40612230bac3d/d6032/mariage_chateau_salle.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "125.15337423312883%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAZABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAMEAgX/xAAWAQEBAQAAAAAAAAAAAAAAAAACAwH/2gAMAwEAAhADEAAAAV0o0HslJp1fO6NZg43P/8QAGxAAAgIDAQAAAAAAAAAAAAAAAQIAEQMSIjP/2gAIAQEAAQUCvnGbDtTMhmjTIGLK+sFWahies//EABURAQEAAAAAAAAAAAAAAAAAABEg/9oACAEDAQE/AWP/xAAWEQEBAQAAAAAAAAAAAAAAAAARABD/2gAIAQIBAT8BI3//xAAcEAACAgIDAAAAAAAAAAAAAAAAAQIRECEicYH/2gAIAQEABj8CTZ6Mp9nF0aVii8xIY//EAB0QAQADAAIDAQAAAAAAAAAAAAEAESExQVGRocH/2gAIAQEAAT8hwA7k2IA2+4xLAqlcQyAXnqaAFckyGysZ3l5uMvc/CfCx5Z//2gAMAwEAAgADAAAAEEfeg//EABcRAQEBAQAAAAAAAAAAAAAAAAEAIRH/2gAIAQMBAT8QQ7Dk3b//xAAYEQACAwAAAAAAAAAAAAAAAAAAARAhYf/aAAgBAgEBPxB2Hif/xAAgEAEAAwABAwUAAAAAAAAAAAABABEhQVGx8DGBkaHh/9oACAEBAAE/EN3KaT1Q/YdZAV7y4AtvKQVFBVfA3pCAgy2r47zOuBTYvOwhnijPGE0vCFGhEIgebGdruZ4noT7zP//Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/26a0712157345476aee40612230bac3d/f994e/mariage_chateau_salle.webp 163w", "/static/26a0712157345476aee40612230bac3d/bcd70/mariage_chateau_salle.webp 325w", "/static/26a0712157345476aee40612230bac3d/797b9/mariage_chateau_salle.webp 640w"],
              "sizes": "(max-width: 640px) 100vw, 640px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/26a0712157345476aee40612230bac3d/367e5/mariage_chateau_salle.jpg 163w", "/static/26a0712157345476aee40612230bac3d/ab07c/mariage_chateau_salle.jpg 325w", "/static/26a0712157345476aee40612230bac3d/d6032/mariage_chateau_salle.jpg 640w"],
              "sizes": "(max-width: 640px) 100vw, 640px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/26a0712157345476aee40612230bac3d/d6032/mariage_chateau_salle.jpg",
              "alt": "location de salles nancy",
              "title": "location de salles nancy",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span><strong parentName="p">{`Location de salles nancy`}</strong></p>
    <p>{`Lieu historique ne vous oblige en aucun cas à choisir un thème de mariage axé sur un voyage dans le temps et un retour vers une époque d’antan. Si le thème historique en fait partie, plusieurs autres thèmes se `}<strong parentName="p">{`marient`}</strong>{` également bien avec le cadre du Château de Morey. Un `}<strong parentName="p">{`thème de mariage`}</strong>{` axé sur la nature, les œuvres littéraires ou cinématographiques, les spécialités d’une région particulière, les loisirs tels que le cirque ou le carnaval seront toujours en parfait accord avec ce château du XVIe.`}</p>
    <p>{`Le Château de Morey sélectionne pour vous les meilleurs prestataires pour un `}<strong parentName="p">{`mariage clef en main à Nancy`}</strong>{`, avec des budgets pour tous, que ce soit pour les petites fêtes, ou pour les plus grandes réceptions. Ainsi, n’hésitez pas à prendre contact, à visiter les lieux et faire votre réservation. Pour un `}<strong parentName="p">{`mariage clef en main`}</strong>{` unique !`}</p>
    <Form url="https://form.typeform.com/to/LQT69N" mdxType="Form" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      